/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useEffect, useState } from "react";
import Api from "../../../utils/Api";
import SessionStore from "../../../stores/SessionStore";
import { Box, InlineNotification, Link, Text, useToast, useTranslation } from "@familyzone/component-library";
import { SyncStatus } from "./SyncStatus";
import { SyncCredentialStatus } from "./SyncCredentialStatus";
import PageWithHeader from "../../templates/PageWithHeader";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
} from "@chakra-ui/react";
import DumbBusyIndicator from "../../../modules/DumbBusyIndicator";
import { CheckBoxControl } from "./ConfigForm/CheckboxControl";
import { CheckBoxWithInputControl } from "./ConfigForm/CheckboxWithInputControl";
import { AdvanceConfigurationConfirmModal } from "./ConfigForm/AdvanceConfigurationConfirmModal";

export interface ConfigurationResponse {
  status: Status;
  configuration: Configuration;
}

interface Status {
  deviceid: string;
  azuread_sync_state?: "finished" | "error" | "running";
  azuread_sync_start_time?: number;
  azuread_sync_end_time?: number;
  azuread_sync_errors?: string;
  azuread_sync_group_count?: number;
  azuread_sync_user_count?: number;
}

interface Configuration {
  azuread_access_token: string;
  azuread_domain: string;
  azuread_enabled: boolean;
  azuread_group_name_field: string;
  azuread_refresh_token: string;
  azuread_strip_domain: boolean;
  azuread_sync_end_time: number;
  azuread_sync_errors: string;
  azuread_sync_group_count: number;
  azuread_sync_hidden_group_memberships: boolean;
  azuread_sync_start_time: number;
  azuread_sync_state: string;
  azuread_sync_user_count: number;
  status: Status;
}

enum GroupNameField {
  displayName = "displayName",
  description = "description",
}

type AzureConfigInputs = {
  enabled: boolean;
  name?: string;
  strip_domain: boolean;
  strip_domain_value?: string; // these are commas seperated lists
  sync_hidden_group_memberships: boolean;
  group_name_field: GroupNameField;
};

export const AzureConfiguration: React.FC = () => {
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const _handleLoad = useCallback(async (): Promise<AzureConfigInputs> => {
    return new Promise<AzureConfigInputs>((resolve, reject) => {
      Api.get(
        "/config/ajax/authentication/azure",
        (result: ConfigurationResponse) => {
          const configuration = result.configuration;

          setTokenExists(!!configuration.azuread_access_token);

          resolve({
            enabled: configuration.azuread_enabled,
            strip_domain: configuration.azuread_strip_domain,
            strip_domain_value: configuration.azuread_domain,
            sync_hidden_group_memberships: configuration.azuread_sync_hidden_group_memberships,
            group_name_field:
              configuration.azuread_group_name_field === "description" ? GroupNameField.description : GroupNameField.displayName,
          });
        },
        () => {
          reject("failed to load config");
        }
      );
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isDirty, isSubmitting, isLoading, dirtyFields },
  } = useForm<AzureConfigInputs>({
    defaultValues: _handleLoad, // we use an async func here so the form "isLoading" state is true while doing initial load
  });

  const enabled = watch("enabled");
  const stripDomain = watch("strip_domain");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const device: string = SessionStore.getSelectedDevice() as string;

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication/portals", isActive: false },
    { title: t("Microsoft Entra ID"), url: "/config/device/authentication/azure", isActive: true },
  ];

  const handleLoad = useCallback(async (): Promise<void> => {
    reset(await _handleLoad());
  }, [reset, _handleLoad]);

  useEffect(() => {
    void handleLoad();
  }, [handleLoad]);

  const _submit = useCallback(
    (input: AzureConfigInputs) => {
      return new Promise<void>((resolve, reject) => {
        const object = {
          azuread_enabled: input.enabled,
          azuread_strip_domain: input.strip_domain,
          azuread_domain: input.strip_domain_value
            ? input.strip_domain_value
                .split(",")
                .map((v) => v.trim())
                .join(",")
            : "",
          azuread_sync_hidden_group_memberships: input.sync_hidden_group_memberships,
          azuread_group_name_field: input.group_name_field,
        };

        Api.post(
          "/config/ajax/authentication/azure",
          object,
          async () => {
            successToast({
              title: t("Configuration Saved"),
              description: t("Configuration settings for Microsoft Entra ID have been saved."),
            });
            await handleLoad();
            resolve();
          },
          () => {
            errorToast({
              title: t("Failed to save Configuration"),
              description: t("An unknown error occurred while trying to save configuration."),
            });
            reject("failed to load config");
          }
        );
      });
    },
    [handleLoad, successToast, errorToast, t]
  );

  const onSubmit: SubmitHandler<AzureConfigInputs> = async (data) => {
    // If we are already showing the modal, submit it
    if (showConfirmModal) {
      setShowConfirmModal(false);
      return _submit(data);
    }
    if (dirtyFields.strip_domain || dirtyFields.strip_domain_value) {
      setShowConfirmModal(true);
      return;
    }
    return _submit(data);
  };

  const handleSubmitConfirmationConfirm = () => {
    void handleSubmit(onSubmit)();
  };

  const handleRevoke = () => {
    Api.post("/config/ajax/authentication/azure/revoke", {}, handleLoad);
  };

  const handleLink = () => {
    Api.post("/config/ajax/authentication/azure/link", {}, (result: { url: Location | (string & Location) }) => {
      window.location = result.url;
    });
  };

  const handleSync = useCallback(() => {
    Api.post("/config/ajax/authentication/azure/sync", {}, handleLoad);
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    manuallySetRunningSyncs[device.concat("_AzureLockout")] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
    localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
  }, [device, handleLoad]);

  return (
    <PageWithHeader title={"Microsoft Entra ID"} breadcrumbs={breadcrumbs}>
      <Box display={"flex"} flex={1} padding={"24px"} gap={"16px"}>
        <Box flex={1} minWidth={"500px"}>
          <Box background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"1rem"} borderRadius={"6px"}>
            <Box padding={"20px 20px 0 20px"} display={"flex"} flexDir={"row"} gap={"4px"}>
              <Text fontSize={14}>
                {"Find out how to"}{" "}
                <Link isExternal href={"https://help.linewize.com/hc/en-gb/articles/5732866996124"}>
                  sync Microsoft Entra ID with School Manager
                </Link>
              </Text>
            </Box>
            {isLoading && (
              <Box display={"flex"} justifyContent={"center"} flex={1}>
                <DumbBusyIndicator loaded={!isLoading} />
              </Box>
            )}
            {!isLoading && (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <form onSubmit={handleSubmit(onSubmit)} data-testid={"azure-config-form"}>
                <Box sx={{ display: "flex", flexDirection: "column", padding: "24px", gap: "16px", fontSize: "14px" }}>
                  <FormControl
                    isInvalid={!!errors.enabled}
                    sx={{
                      display: "flex",
                      minHeight: "40px",
                      gap: "16px",
                    }}
                  >
                    <FormLabel
                      htmlFor="enabled"
                      sx={{
                        width: "120px",
                        alignContent: "center",
                      }}
                    >
                      Enabled
                    </FormLabel>
                    {/*
                    I am not sure why I can to specifically set this enabled value here, I didn't need to for other checkboxes,
                    but the other check boxes do conditionally render on this enabled value too.
                  */}
                    <Checkbox isChecked={enabled} {...register("enabled", {})} />
                    <FormErrorMessage>{errors.enabled && errors.enabled.message}</FormErrorMessage>
                  </FormControl>
                  {enabled && (
                    <>
                      <Box
                        sx={{
                          minHeight: "40px",
                          backgroundColor: "#F4F5F7",
                          alignContent: "center",
                          padding: "12px",
                        }}
                      >
                        <Text fontWeight={500}>Groups</Text>
                      </Box>
                      <CheckBoxControl
                        label={"Sync Hidden Group Memberships"}
                        register={register}
                        errors={errors}
                        propertyName={"sync_hidden_group_memberships"}
                        tooltip={'Syncs groups created by School Data Sync (SDS) with the "HiddenMemberships" property.'}
                      />
                      <Divider />
                      <Controller
                        control={control}
                        name="group_name_field"
                        rules={{ required: "Please select a group name field." }}
                        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                          <FormControl
                            isInvalid={!!errors.group_name_field}
                            sx={{
                              display: "flex",
                              minHeight: "40px",
                              gap: "16px",
                            }}
                          >
                            <FormLabel
                              htmlFor="group_name_field"
                              sx={{
                                width: "120px",
                                alignContent: "center",
                                flexShrink: "0",
                              }}
                            >
                              Group Name Field
                            </FormLabel>
                            <Select
                              data-testid={"name-field-select"}
                              name={name}
                              ref={ref}
                              iconSize={"0"}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              size="lg"
                              variant={"outline"}
                              defaultValue={"displayName"}
                              sx={{
                                width: "100%",
                                height: "40px",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                border: "1px solid #EBECF0",
                                paddingLeft: "16px",
                              }}
                            >
                              <option value={"displayName"}>Group Name</option>
                              <option value={"description"}>Group Description</option>
                            </Select>
                            <FormErrorMessage>{error && error.message}</FormErrorMessage>
                          </FormControl>
                        )}
                      />

                      {/* by providing the defaultIndex we can start this expanded if they have strip domain already enabled */}
                      <Accordion
                        allowToggle
                        defaultIndex={stripDomain ? 0 : undefined}
                        sx={{
                          border: "1px solid #F4F5F7",
                          borderRadius: "6px",
                        }}
                      >
                        <AccordionItem>
                          <AccordionButton>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                minHeight: "40px",
                                backgroundColor: "#F4F5F7",
                                alignContent: "center",
                                alignItems: "center",
                                padding: "12px",
                                gap: "8px",
                              }}
                            >
                              <AccordionIcon />

                              <Text fontWeight={500}>Advanced Configuration</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "16px",
                              fontSize: "14px",
                              gap: "8px",
                            }}
                          >
                            <Text>
                              Advanced sync settings are for complex deployments only. Consult with the Linewize support team before making
                              any changes.
                            </Text>
                            {stripDomain && (
                              <InlineNotification
                                status="warning"
                                notificationTitle={"User names need to be unique when using strip domains. "}
                                notificationDescription={
                                  "Strip Domain is used to modify usernames from the directory. Before enabling this feature ensure usernames without domains are unique. If usernames are not unique this will result in errors."
                                }
                              />
                            )}
                            <CheckBoxWithInputControl
                              label={"Strip Domain"}
                              register={register}
                              errors={errors}
                              propertyName={"strip_domain"}
                              tooltip={"Turn on to import users as username instead of username@example.com."}
                              inputPropertyName={"strip_domain_value"}
                              inputRegisterOptions={{
                                required: stripDomain ? "Domains are required if Strip Domain is enabled." : false,
                                minLength: { value: 3, message: "Minimum length should be 3" },
                                pattern: {
                                  value: /^(?!.*:\/\/)(([^,\s]+\s*,\s*)*[^,\s]+)$/,
                                  message: "Please enter domains without a protocol.",
                                },
                              }}
                              inputTestId={"strip_domain-input"}
                              enabled={stripDomain}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    padding: "24px",
                    background: "#F5FCFF",
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    data-testid={"submitBtn"}
                    mt={4}
                    disabled={!isDirty || isSubmitting}
                    colorScheme="teal"
                    type="submit"
                    variant={"primary"}
                  >
                    Submit
                  </Button>
                  <DumbBusyIndicator loaded={!isSubmitting} />
                </Box>
                <AdvanceConfigurationConfirmModal
                  isOpen={showConfirmModal}
                  onClose={() => setShowConfirmModal(false)}
                  onSubmit={handleSubmitConfirmationConfirm}
                />
              </form>
            )}
          </Box>
        </Box>
        {enabled && (
          <Box width={"450px"} gap={"16px"} display={"flex"} flexDir={"column"}>
            <SyncStatus
              syncType={"AZURE"}
              handleRunSync={tokenExists && !isDirty ? handleSync : undefined}
              syncLockoutTime={
                device
                  ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_AzureLockout")]
                    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_AzureLockout")]
                    : undefined
                  : undefined
              }
            />
            <SyncCredentialStatus isLinked={tokenExists} handleLink={handleLink} handleRevoke={handleRevoke} syncType={"AZURE"} />
          </Box>
        )}
      </Box>
    </PageWithHeader>
  );
};
