import React, { useLayoutEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Link } from "react-router";
import { useQuery } from "react-query";
import Api from "../../../utils/Api";
import InfoIconV2 from "../../../modules/InfoIconV2";
import NoData from "../../../modules/NoData";
import ComponentLoading from "../../../modules/ComponentLoading";
import clsx from "clsx";
import { useForceUpdate } from "../../../utils/hooks/useForceUpdate";
import { getAmount } from "../../../modules/AmountField";

const topSixAppsKey = {
  id: "top-six-apps",
  dates: (apiDateOverride) => [topSixAppsKey.id, Object.values(apiDateOverride).join("-")],
};

export const TopSixApps = (props) => {
  const chartRef = useRef(null);
  const forceUpdate = useForceUpdate();

  const topSixAppsQuery = useQuery(topSixAppsKey.dates(props.apiDateOverride), () =>
    Api.get_analyticsAsync("/surfwize/analytics/traffic/topapps?pagesize=6", { dateOverride: props.apiDateOverride }).then(
      (result) => result.data
    )
  );

  const chartColorList = useMemo(
    () => [
      new am4core.color("#2F2CB7"),
      new am4core.color("#A56EFF"),
      new am4core.color("#DD7A01"),
      new am4core.color("#06A59A"),
      new am4core.color("#0D9DDA"),
      new am4core.color("#E3066A"),
    ],
    []
  );

  const useSingleColumnLegend = useRef(true);
  const legend = () => {
    const hoverSlice = (item, hover) => {
      return () => {
        const slice = chartRef?.current?.series.values[0].slices.getIndex(item);
        if (slice) {
          slice.isHover = hover;
        }
      };
    };

    let total = 0;
    chartRef.current?.data?.forEach((item) => (total += item.transfer));
    return chartRef.current?.data.map((item, i) => {
      const itemId = `legend-item-${i}`;
      const itemStyle = { background: chartColorList[i] };
      const percent = Math.round((item.transfer / total) * 1000) / 10; // Round to one decimal place to match the graph
      return (
        <div
          key={i}
          className={clsx("legend-item", useSingleColumnLegend.current && "legend-item-single-column")}
          id={itemId}
          onMouseOver={hoverSlice(i, true)}
          onMouseOut={hoverSlice(i, false)}
        >
          <div
            className={clsx("legend-marker", !useSingleColumnLegend.current && i % 2 === 1 && "legend-marker-inner")}
            style={itemStyle}
          ></div>
          <div className="legend-label">{item.app}</div>
          <div className={clsx("legend-value", !useSingleColumnLegend.current && i % 2 === 0 && "legend-value-inner")}>({percent}%)</div>
        </div>
      );
    });
  };

  const switchLegendLayoutThreshold = 1600;
  useLayoutEffect(() => {
    function handleResize() {
      if (useSingleColumnLegend.current !== window.innerWidth < switchLegendLayoutThreshold) {
        useSingleColumnLegend.current = window.innerWidth < switchLegendLayoutThreshold;
        ReactDOM.render(legend(), document.getElementById("personaTopSixAppsChartLegend"));
      }
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useLayoutEffect(() => {
    const chart = am4core.create("personaTopSixAppsChart", am4charts.PieChart);
    chart.numberFormatter.numberFormat = "#.0";

    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "transfer";
    pieSeries.dataFields.category = "app";
    pieSeries.slices.template.propertyFields.dummyData = "formatTransfer";
    pieSeries.slices.template.tooltipText = "{category}: {value.percent}% ({dummyData})";

    chart.innerRadius = am4core.percent(60);
    pieSeries.slices.template.stroke = am4core.color("#ffffff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.tooltip.zIndex = Infinity;

    const colorSet = new am4core.ColorSet();
    colorSet.list = chartColorList;

    pieSeries.colors = colorSet;

    chartRef.current = chart;
  }, [chartColorList]);

  useLayoutEffect(() => {
    const chart = chartRef.current;
    chart.data = topSixAppsQuery.data?.map((x) => {
      return {
        app: x["tag.string"] ? x["tag.string"] : x["key"],
        transfer: x.transfer,
        formatTransfer: getAmount(x.transfer), //In future will use byte-size package. See edu-2247
      };
    });
    chart.data.sort(function (a, b) {
      return b["transfer"] - a["transfer"];
    });

    forceUpdate();
  }, [forceUpdate, topSixAppsQuery.data]);

  const showNoData = !topSixAppsQuery.isLoading && chartRef.current?.data?.length === 0;

  return (
    <div id="topSixAppsModule" className="personaModule">
      <div className="personaModule-header">
        <h2>Top 6 Apps</h2>
        <InfoIconV2 backgroundColor="grey" text="Top apps accessed for your selected time period" />
        <Link to={"/surfwize/reporting/types"} className="personaModule-rightLink">
          View All
        </Link>
      </div>
      {topSixAppsQuery.isLoading && (
        <div className="centered">
          <ComponentLoading />
        </div>
      )}
      {showNoData && <NoData />}
      <div id="personaTopSixAppsChart" className={clsx("", (topSixAppsQuery.isLoading || showNoData) && "hidePersonaChart")}></div>
      <div id="personaTopSixAppsChartLegend">{legend()}</div>
    </div>
  );
};
